<template>
  <el-dialog
    title="修改收货信息"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="cancel"
    :close-on-click-modal="false"
    width="600px"
  >
    <div class="content">
      <el-form :model="info" :rules="rules" ref="ruleForm" label-width="120px">
        <el-form-item label="收货人姓名" prop="contactName">
          <el-input v-model="info.contactName"></el-input>
        </el-form-item>
        <el-form-item label="收货人电话" prop="mobileNumber">
          <el-input v-model="info.mobileNumber"></el-input>
        </el-form-item>
        <el-form-item label="收货人地址" prop="areaCode">
          <div class="three">
            <el-select
              v-model="info.provinceCode"
              placeholder="请选择省份"
              @change="changeProvince"
            >
              <el-option
                v-for="item in provinces"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
            <el-select
              v-model="info.cityCode"
              placeholder="请选择城市"
              @change="changeCity"
            >
              <el-option
                v-for="item in cities"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
            <el-select v-model="info.areaCode" placeholder="请选择区">
              <el-option
                v-for="item in countries"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="收货人详细地址" prop="streetAddress">
          <el-input v-model="info.streetAddress" placeholder="请输入地址"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer">
      <el-button plain @click="cancel">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as commonService from '@/api/common'
import axios from '@/api/axios'

export default {
  name: 'express',
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    orderId: {
      type: [String, Number],
      require: true
    }
  },
  data () {
    return {
      info: {},
      provinces: [],
      cities: [],
      countries: [],
      rules: {
        contactName: [{ require: true, trigger: 'blur' }],
        mobileNumber: [{ require: true, trigger: 'blur' }],
        areaCode: [{ require: true, trigger: 'blur' }],
        streetAddress: [{ require: true, trigger: 'blur' }]
      }
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.getRecever()
      }
    }
  },
  created () {
    this.fetchProvinces()
  },
  methods: {
    async getRecever () {
      const res = await axios.get(`/management/order/address/${this.orderId}`)
      this.info = {
        orderId: res.orderId,
        contactName: res.contactName,
        mobileNumber: res.mobileNumber,
        provinceCode: res.provinceCode,
        provinceName: res.provinceName,
        cityCode: res.cityCode,
        cityName: res.cityName,
        areaCode: res.areaCode,
        areaName: res.areaName,
        streetAddress: res.streetAddress
      }
      this.handleProvniceChange(true)
      this.handleCityChange()
    },
    async fetchProvinces () {
      const res = await commonService.fetchProvinces()
      this.provinces = res.list
    },
    async handleProvniceChange (val) {
      const res = await commonService.fetchCities(this.info.provinceCode)
      this.cities = res.list
      if (!val) {
        this.countries = []
      }
    },
    async handleCityChange () {
      const res = await commonService.fetchCountries(
        this.info.provinceCode,
        this.info.cityCode
      )
      this.countries = res.list
    },
    changeProvince () {
      this.handleProvniceChange()
      this.info.cityCode = ''
      this.info.areaCode = ''
    },
    changeCity () {
      this.handleCityChange()
      this.info.areaCode = ''
    },
    cancel () {
      this.$emit('onCancel')
    },
    async save () {
      this.info.provinceName = this.provinces.find(item => item.code === this.info.provinceCode).name
      this.info.cityName = this.cities.find(item => item.code === this.info.cityCode).name
      this.info.areaName = this.countries.find(item => item.code === this.info.areaCode).name
      try {
        await axios.put('/management/order/modify', this.info)
        this.$notify.success('修改成功！')
        this.$emit('confirm')
      } catch (error) {
        this.$notify.error('修改失败！')
      }
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-dialog__body {
  padding: 0 20px;
  .content {
    height: calc(50vh);
    overflow-y: auto;
    padding-top: 20px;
    overflow-x: hidden;
    .three {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 11px;
      margin-bottom: 13px;
    }
  }
  .footer {
    border-top: 1px solid #d9e0f0ff;
    padding: 20px 0;
    text-align: right;
  }
}
</style>
