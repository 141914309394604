<template>
  <el-dialog title="修改收货信息" :visible.sync="visible" :append-to-body="true" :before-close="cancel" :close-on-click-modal="false" width="500px">
    <div class="content">
      <el-form :model="info" ref="ruleForm" label-width="100px">
        <el-form-item label="物流公司" prop="deliveryCompany">
          <el-select v-model="info.deliveryCompany" style="width: 250px;">
            <el-option v-for="item in deliveryList" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物流单号" prop="deliveryCode">
          <el-input v-model="info.deliveryCode" style="width: 250px;"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer">
      <el-button plain @click="cancel">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import axios from '@/api/axios'

export default {
  props: {
    orderId: {
      type: [String, Number],
      require: true
    },
    type: {
      type: String,
      require: true
    }
  },
  data () {
    return {
      visible: true,
      info: {
        deliveryCompany: '',
        deliveryCode: ''
      },
      deliveryList: [
        { name: '顺丰速运', value: 'SF' },
        { name: '百世快递', value: 'HTKY' },
        { name: '中通快递', value: 'ZTO' },
        { name: '申通快递', value: 'STO' },
        { name: '圆通速递', value: 'YTO' },
        { name: '韵达速递', value: 'YD' },
        { name: '邮政快递包裹', value: 'YZPY' },
        { name: 'EMS', value: 'EMS' },
        { name: '京东快递', value: 'JD' },
        { name: '优速快递', value: 'UC' },
        { name: '德邦快递', value: 'DBL' },
        { name: '极兔速递', value: 'JTSD' },
        { name: '众邮快递', value: 'ZYE' },
        { name: '宅急送', value: 'ZJS' }
      ]
    }
  },
  methods: {
    cancel () {
      this.$emit('onCancel')
    },
    save () {
      if (this.type === 'new') {
        this.newSave()
      } else {
        this.editSave()
      }
    },
    async newSave () {
      try {
        await axios.post(`/management/order/inventedsend/${this.orderId}?deliveryCompany=${this.info.deliveryCompany}&deliveryCode=${this.info.deliveryCode}`)
        this.$notify.success('虚拟发货成功！')
        this.$emit('confirm')
      } catch (error) {
        this.$notify.error('虚拟发货失败！')
      }
    },
    async editSave () {
      try {
        await axios.put(`/management/order/change-express/invented?id=${this.orderId}&deliveryCompany=${this.info.deliveryCompany}&deliveryCode=${this.info.deliveryCode}`)
        this.$notify.success('修改虚拟发货成功！')
        this.$emit('confirm')
      } catch (error) {
        this.$notify.error('修改虚拟发货失败！')
      }
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-dialog__body {
  padding: 0 20px;
  .content {
    height: 130px;
    overflow-y: auto;
    padding-top: 20px;
    overflow-x: hidden;
    .three {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 11px;
      margin-bottom: 13px;
    }
  }
  .footer {
    border-top: 1px solid #d9e0f0ff;
    padding: 20px 0;
    text-align: right;
  }
}
</style>
